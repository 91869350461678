import React from 'react';

import { Container } from 'reactstrap';
import { ListGroup } from 'react-bootstrap';

const RefundPolicy = (props) => {
	return (
		<div className="section section-hero section-shaped bg-gradient-default">
			<div
				className="section section-shaped section-lg"
				style={{ minHeight: '100vh' }}>
				<Container
					fixed
					className="p-3 mb-3 shadow-lg"
					style={{
						marginBottom: '90px',
						paddingLeft: '3px',
						paddingRight: '3px',
					}}>
					<h2 className="text-white text-center">
						Refund & Cancellation Policy
					</h2>
					<ListGroup>
						<ListGroup.Item>
							<p>
								Please read and make sure you fully understand our refund policy
								before making a payment.
							</p>
							<p>
								Abo Server operates as a business and we expect our CUSTOMERS to
								understand and comply with our policies. Our goal as a
								consulting and web development company is to bring the most
								value to our CLIENTS, and in order to help us in this pursuit,
								we must cover expenses and fuel our operations. Therefore, we
								require that payments be made on time for services provided to
								the CLIENT.
							</p>
							<p>
								THIS IS OUR REFUND AND CANCELLATION POLICY AND BY HIRING ABO
								SERVER FOR SERVICES, YOU ARE BOUND BY THIS AGREEMENT AND AGREE
								TO ABIDE BY OUR POLICIES.
							</p>
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>Consulting</h6>
							<p>
								A consulting session is not refundable after it has been
								completed. The CUSTOMER must notify Abo Server 2 hours prior to
								a consulting session to cancel and obtain a full refund.
								Otherwise, only a partial refund will be issued.
							</p>
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>Web Development</h6>
							<p>
								Web design and development projects are resource intensive, time
								consuming and involve internal expenses. Therefore, once a
								payment or deposit is made, it is only refundable under certain
								conditions. If a project is cancelled, delayed or postponed by
								the CUSTOMER, all monies paid may be refunded in full, provided
								that Abo Server has been notified prior to any internal
								involvement. Otherwise a partial refund based on work already
								done will be made.
							</p>
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>
								Search Engine Optimization, Social Media Marketing and Internet
								Marketing
							</h6>
							<p>
								Payments for search engine optimization, social media marketing
								or any of our internet marketing services are non-refundable.
							</p>
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>Set-up Fee</h6>
							Setup fees are applied directly to expenses incurred by Abo Server
							in the setup phase of any SEO or Internet marketing campaign and
							are non-refundable.
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>Third Party Tool Fees</h6>
							<p>
								Third-party tool fees are applied directly to expenses incurred
								by Abo Server in the integration and use of any application or
								software and are non-refundable.
							</p>
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>Web Hosting</h6>
							<p>
								All hosting payments, whether for the current month or previous
								months, are non-refundable. Web hosting accounts are set up from
								the date of your order and are assigned a limited amount of
								server resources based on the plan you purchased.
							</p>
							<p>
								If CUSTOMER opens a hosting account but does not use it, payment
								will still be due. Hosting billing cycles continue until written
								notice of cancellation is received from CUSTOMER.
							</p>
						</ListGroup.Item>
						<ListGroup.Item>
							<h6>Account Suspension</h6>
							<p>
								All hosting payments that are 15 days past due will trigger an
								account suspension. Hosting paid up to 12 months in advance and
								cancelled prior to the expiration date will be refunded for the
								remaining months.
							</p>
						</ListGroup.Item>
					</ListGroup>
				</Container>
			</div>
		</div>
	);
};
export default RefundPolicy;
