import { observable, action } from 'mobx';

export class TranslateStore {
    @observable
    translation: any = {
        buyNow: 'Renew',
        sale: 'SALE',
        addToCart: 'Add To Cart',
        purchasedProducts: 'Thank you for your purchase!',
        accountInformation: 'Account Information',
        checkSN: 'Check S/N',
        readyToBuy: 'Ready to buy?',
        purchaseMore: 'Purchase More',
        cart: 'Cart',
        addProductsToCart: 'Add products to the cart',
        total: 'Total',
        byStripe: 'Purchase Powered By Stripe',
        paymentStatus: 'Payment Status',
        pay: 'Pay',
        buyNowText: 'BUY NOW AT SALE ONLY 36 EUR',
        serialNumber: 'Find the serial number of your device in menu>settings>system information.',
        trustText: 'The padlock and the address “https” indicate that the page is totally secure.',
        signIn: 'Sign In',
        signUp: 'Sign Up',
        gshare: 'gshare/',
        funcam: 'funcam/',
        forever: 'forever/'
    };

    @action
    setTranslation(language: any) {
        if (language ==='en') {
            this.translation = {
                buyNow: 'Renew',
                sale: 'SALE',
                addToCart: 'Add To Cart',
                purchasedProducts: 'Purchased Products',
                accountInformation: 'Account Information',
                checkSN: 'Check S/N',
                readyToBuy: 'Ready to buy?',
                purchaseMore: 'Purchase More',
                cart: 'Cart',
                addProductsToCart: 'Add products to the cart',
                total: 'Total',
                byStripe: 'Purchase Powered By Stripe',
                paymentStatus: 'Payment Status',
                pay: 'Pay',
                buyNowText: 'BUY NOW AT SALE ONLY 36 EUR',
                serialNumber: 'Suchen Sie die Seriennummer Ihres Geräts unter Menü> Einstellungen> Systeminformationen.',
                trustText: 'The padlock and the address “https” indicate that the page is totally secure.',
                signIn: 'Sign In',
                signUp: 'Sign Up',
                gshare: 'gshare/',
                funcam: 'funcam/',
                forever: 'forever/'

            };
        } else if (language ==='de') {
            this.translation = {
                buyNow: 'Kaufe jetzt',
                sale: 'VERKAUF',
                addToCart: 'In den Warenkorb legen',
                purchasedProducts: 'Gekaufte Produkte',
                accountInformation: 'Kontoinformationen',
                checkSN: 'Überprüfen Sie S/N',
                readyToBuy: 'Bereit zu kaufen?',
                purchaseMore: 'Mehr kaufen',
                cart: 'Wagen',
                addProductsToCart: 'Produkte in den Warenkorb legen',
                total: 'Gesamt',
                byStripe: 'Kauf Powered By Stripe',
                paymentStatus: 'Zahlungsstatus',
                pay: 'Zahlen',
                buyNowText: 'JETZT BEI VERKAUF NUR 36 EUR KAUFEN',
                serialNumber: 'Find the serial number of your device in menu>settings>system information.',
                trustText: 'Das Vorhängeschloss und die Adresse „https” geben an, dass die Seite absolut sicher ist.',
                gshare: 'gshare-german/',
                funcam: 'funcam-german/',
                forever: 'forever-german/'
            };
        } else if (language ==='sp') {
            this.translation = {
                buyNow: 'Compra ahora',
                sale: 'REBAJA',
                addToCart: 'Añadir al carrito',
                purchasedProducts: 'Productos comprados',
                accountInformation: 'Información de la cuenta',
                checkSN: 'Verificar S/N',
                readyToBuy: '¿Listo para comprar?',
                purchaseMore: 'Comprar más',
                cart: 'Carro',
                addProductsToCart: 'Agregar productos al carrito',
                total: 'Total',
                byStripe: 'Compra Powered By Stripe',
                paymentStatus: 'Estado de pago',
                pay: 'Pagar',
                buyNowText: 'COMPRE AHORA A LA VENTA SOLO 36 EUR',
                serialNumber: 'Busque el número de serie de su dispositivo en menú> configuración> información del sistema.',
                trustText: 'El candado y la dirección «https» indican que la página es totalmente segura.',
                gshare: 'gshare-spain/',
                funcam: 'funcam-spain/',
                forever: 'forever-spain/'
            };
        } else if (language ==='it') {
            this.translation = {
                buyNow: 'Acquista ora',
                sale: 'VENDITA',
                addToCart: 'Aggiungi al carrello',
                purchasedProducts: 'Prodotti acquistati',
                accountInformation: 'Informazioni account',
                checkSN: 'Controlla S/N',
                readyToBuy: 'Pronto per l\'acquisto?',
                purchaseMore: 'Acquista di più',
                cart: 'Carrello',
                addProductsToCart: 'Aggiungi prodotti al carrello',
                total: 'Totale',
                byStripe: 'Acquisto alimentato da Stripe',
                paymentStatus: 'Stato del pagamento',
                pay: 'pagare',
                buyNowText: 'ACQUISTA ORA IN SALDO SOLO 36 EUR',
                serialNumber: 'Trova il numero di serie del tuo dispositivo in menu> impostazioni> informazioni di sistema.',
                trustText: 'Il lucchetto e l’indirizzo «https» indicano che la pagina è totalmente sicura.',
                gshare: 'gshare-italian/',
                funcam: 'funcam-italian/',
                forever: 'forever-italian/'
            };
        } else if (language ==='fr') {
            this.translation = {
                buyNow: 'Acheter maintenant',
                sale: 'VENTE',
                addToCart: 'Ajouter au chariot',
                purchasedProducts: 'Produits achetés',
                accountInformation: 'Information sur le compte',
                checkSN: 'Vérifier S/N',
                readyToBuy: 'Prêt à acheter?',
                purchaseMore: 'Achetez plus',
                cart: 'Panier',
                addProductsToCart: 'Ajouter des produits au panier',
                total: 'Total',
                byStripe: 'Achat Powered By Stripe',
                paymentStatus: 'Statut de paiement',
                pay: 'Payer',
                buyNowText: 'ACHETER MAINTENANT EN SOLDE SEULEMENT 36 EUR',
                serialNumber: 'Trouvez le numéro de série de votre appareil dans menu>paramètres>information système.',
                trustText: 'Le cadenas et l’adresse « https » indiquent que la page est totalement sécurisée.',
                signIn: 'Se connecter',
                signUp: 'S’enregistrer',
                gshare: 'gshare-french/',
                funcam: 'funcam-french/',
                forever: 'forever-french/'
            };
        } else if (language ==='pt' ) {
            this.translation = {
                buyNow: 'Compre Agora',
                sale: 'VENDA',
                addToCart: 'Adicionar ao carrinho',
                purchasedProducts: 'Produtos comprados',
                accountInformation: 'Informação da conta',
                checkSN: 'Verifica S/N',
                readyToBuy: 'Pronto para comprar?',
                purchaseMore: 'Compre mais',
                cart: 'Carrinho',
                addProductsToCart: 'Adicionar produtos ao carrinho',
                total: 'Total',
                byStripe: 'Compra Powered By Stripe',
                paymentStatus: 'Status do pagamento',
                pay: 'Pagamento',
                buyNowText: 'compre agora à venda por apenas 36€',
                serialNumber: 'Encontre o número de série do seu dispositivo em menu> configurações> informações do sistema.',
                trustText: 'O cadeado e o endereço “https” indicam que a página é totalmente segura.',
                gshare: 'gshare/',
                funcam: 'funcam/',
                forever: 'forever/'
            };
        } else if (language ==='cz') {
            this.translation = {
                buyNow: 'Obnovit',
                sale: 'výprodej',
                addToCart: 'Přidat do košíku',
                purchasedProducts: 'zakoupené produkty',
                accountInformation: 'Account Information',
                checkSN: 'Check S/N',
                readyToBuy: 'Jste připraveni koupit?',
                buyVíce: 'Koupit více',
                košík: 'Košík',
                addProductsToCart: 'Přidat produkty do košíku',
                celkem: 'Celkem',
                byStripe: 'Nákup založený na pruhu',
                paymentStatus: 'Stav platby',
                pay: 'Pay',
                buyNowText: 'KOUPIT NYNÍ K PRODEJI POUZE 36 EUR',
                serialNumber: 'Vyhledejte sériové číslo zařízení v nabídce> nastavení> systémové informace.',
                trustText: 'Visací zámek a adresa „https“ znamenají, že je stránka zcela bezpečná.',
                gshare: 'gshare/',
                funcam: 'funcam/',
                forever: 'forever/'
            };
        } else if (language ==='sl') {
            this.translation = {
                buyNow: 'Obnovit',
                predaj: 'PREDAJ',
                addToCart: 'Pridať do CartAdd do košíka',
                purchasedProducts: 'zakúpené produkty',
                accountInformation: 'Informácie o účte',
                checkSN: 'Check S/N',
                readyToBuy: 'Ste pripravení kúpiť?',
                purchaseViac: 'Buy More',
                košík: 'Košík',
                addProductsToCart: 'Pridať výrobky do košíka',
                celkom: 'celkom',
                byStripe: 'Nákup pomocou prúžku',
                paymentStatus: 'Stav platby',
                pay: 'Pay',
                buyNowText: 'KÚPIŤ TERAZ ZA PREDAJ IBA 36 EUR',
                serialNumber: 'V ponuke> Nastavenia> Systémové informácie vyhľadajte sériové číslo svojho zariadenia.',
                trustText: 'Visiaci zámok a adresa „https“ znamenajú, že stránka je úplne bezpečná.',
                gshare: 'gshare/',
                funcam: 'funcam/',
                forever: 'forever/'
            };
        } else {
            this.translation = {
                buyNow: 'Renew',
                sale: 'SALE',
                addToCart: 'Add To Cart',
                purchasedProducts: 'Purchased Products',
                accountInformation: 'Account Information',
                checkSN: 'Check S/N',
                readyToBuy: 'Ready to buy?',
                purchaseMore: 'Purchase More',
                cart: 'Cart',
                addProductsToCart: 'Add products to the cart',
                total: 'Total',
                byStripe: 'Purchase Powered By Stripe',
                paymentStatus: 'Payment Status',
                pay: 'Pay',
                buyNowText: 'BUY NOW AT SALE ONLY 36 EUR',
                serialNumber: 'Find the serial number of your device in menu>settings>system information.',
                trustText: 'The padlock and the address “https” indicate that the page is totally secure.',
                signIn: 'Sign In',
                signUp: 'Sign Up',
                gshare: 'gshare/',
                funcam: 'funcam/',
                forever: 'forever/'
            };
        }
    }
}
