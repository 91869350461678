import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translation_de from "./translations/de/translation.json";
import translation_en from "./translations/en/translation.json";
import translation_es from "./translations/en/translation.json";
import translation_fr from "./translations/fr/translation.json";
import translation_it from "./translations/it/translation.json";

const fallbackLng = ['en'];
const availableLanguages = ['de', 'en', 'es', 'fr', 'it'];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLng,
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
        saveMissing: true,
        whitelist: availableLanguages,
        resources: {
            de: {
                translation: translation_de
            },
            en: {
                translation: translation_en
            },
            es: {
                translation: translation_es
            },
            fr: {
                translation: translation_fr
            },
            it: {
                translation: translation_it
            },
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;
