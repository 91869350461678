import { observable, action } from 'mobx';

export class ErrorStore {
    @observable
    usernameError = false;

    @observable
    passwordError = false;

    @observable
    usernameErrorText = '';

    @observable
    passwordErrorText = '';

    @observable
    emailError = false;

    @observable
    emailErrorText = ''

    @action
    setUsernameError(value: any) {
        this.usernameError = value;
    }

    @action
    setPasswordError(value: any) {
        this.passwordError = value;
    }

    @action
    setEmailError(value: any) {
        this.emailError = value;
    }

    @action
    setUsernameErrorText(value: any) {
        this.usernameErrorText = value;
    }

    @action
    setPasswordErrorText(value: any) {
        this.passwordErrorText = value;
    }

    @action
    setEmailErrorText(value: any) {
        this.emailErrorText = value;
    }
}
