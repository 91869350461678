//import * as React from 'react';

import NavMenu from './NavMenu';
import Footer from './Footer';
import {Suspense} from "react";


export default (props) => {
    return (
        <div>
            <NavMenu/>
            <Suspense fallback={<div style={{height:'100vh'}} className='shape shape-style-1 bg-gradient-default'/>}>
                {props.children}
            </Suspense>
            <Footer/>
        </div>
    );
};
