import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Cookies from 'js-cookie';
import LogoImg from '../imgs/nav-logo.png';

import { observer, inject } from "mobx-react";

import styles from './argondesign.module.css';
import useStyles from '../styles/styles';

export default inject('uiStateStore', 'cartStore', 'translateStore')(observer((props) => {
    let history = useHistory();

    const [currentTranslation, setCurrentTranslation] = React.useState('en');
    const [expanded, setExpanded] = React.useState(false);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };
    const closeNav = () => {
      if(expanded) setExpanded(false);
    }
    const classes = useStyles();
    const toggleDrawer = (open) => () => {
        props.uiStateStore.setDrawerState(open);
    };
    const argon = function(){
      let result = "";
      for(let i=0; i < arguments.length; i++){
        result += styles[arguments[i]] + " ";
      }
      return result;
    };
    var user_cookie = '';
    try {
        user_cookie = Cookies.get("mxAkskd1dmA");
    } catch(err) {
       return
    }

    const NewNav = function NavMenu() {
        //const [isOpen, setIsOpen] = React.useState(false);
        //const toggle = () => setIsOpen(!isOpen);
        const gradient = {backgroundImage: "linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important"};
        return(
          <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-dark py-3" style={gradient}>
          <div className="container">
            <a className={argon("navbar-brand", "mr-lg-5") + "no-text-transform font-weight-normal g-font-shippori"} style={{color: "white", cursor: "pointer", fontSize: "1.5em"}} onClick={()=>{history.push("/")}}>
              <img src={LogoImg} /> Abo Server
            </a>
            <button className={"navbar-toggler" + (expanded? ' collapsed': '')} type="button" data-toggle="collapse" aria-expanded={expanded ? 'true': 'false'} aria-label="toggle navigation">
              <span className="navbar-toggler-icon" onClick={toggleExpand}></span>
            </button>
            <div className={"navbar-collapse collapse" + (expanded? ' show': '')} id="navbar_global">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <a className="font-weight-normal g-font-shippori" onClick={()=>{history.push("/"); closeNav();}} style={{whiteSpace: "nowrap", fontSize: "1.5em"}}>
                    <img src={LogoImg} style={{filter: "invert(0.8)"}} /> Abo Server
                    </a>
                  </div>
                  <div className="col-6 collapse-close">
                    <button type="button" onClick={toggleExpand} className="navbar-x navbar-toggler" aria-expanded={expanded? 'true': 'false'} aria-label="toggle navigation">
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                <div className="row">
                  <div className="navbar-col">
                <li className="nav-item">
                  <a className="btn btn-neutral px-2 py-1" onClick={()=>{history.push("/contact-us"); closeNav();}}>
                    <span className="nav-link-inner--text">Contact</span>
                  </a>
                </li>
                </div>
                </div>
              </ul>
            </div>
          </div>
        </nav>
        );
}

    return (
        <header>
            <NewNav />
        </header>
    );
}));
