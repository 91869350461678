//import './styles/argon-dashboard-react.min.css'
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'mobx-react';
import moment from "moment-timezone";
import * as ReactDOM from 'react-dom';
import Moment from "react-moment";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './App.css';
import './i18n.js';
import { CartStore } from './stores/cartStore';
import { ErrorStore } from './stores/errorStore';
import { FormStore } from './stores/formStore';
import { ProductPageStore } from './stores/productPageStore';
import { TranslateStore } from './stores/translateStore';
//import registerServiceWorker from './registerServiceWorker';
import { UiStateStore } from './stores/uiStateStore';
import './styles/argon-design-system-react.css';
import './styles/font-awesome/css/font-awesome.min.css';
import './styles/nucleo/css/nucleo.css';
import * as Sentry from "@sentry/react";
import React from "react";
import { BrowserTracing } from '@sentry/tracing';
import { Replay } from "@sentry/react";

Sentry.init({
    dsn: "https://96ec55f592f10aa505e08f8cf9c60396@o1124488.ingest.sentry.io/4506187700109312",
    integrations: [
      new BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          
        ),
      }),
      new Replay()
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

Moment.globalMoment = moment
Moment.globalTimezone = moment.tz.guess();

const stores = {
    uiStateStore: new UiStateStore(),
    errorStore: new ErrorStore(),
    formStore: new FormStore(),
    cartStore: new CartStore(),
    translateStore: new TranslateStore(),
    productPageStore: new ProductPageStore(),
}

ReactDOM.render(
    <Provider {...stores}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

//registerServiceWorker();
