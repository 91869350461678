import { useHistory } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import Layout from './components/Layout';
import { lazy } from 'react';
import Checkout from './components/Checkout';
import CheckoutCS from './components/CheckoutCS';
import RefundPolicy from './components/RefundPolicy';

const Pricing = lazy(() => import('./components/Pricing'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const Register = lazy(() => import('./pages/Register'));
//const Checkout = lazy(() => import('./components/Checkout'))
const MyAccount = lazy(() => import('./components/MyAccount'));
const CGV = lazy(() => import('./components/CGV'));
const ContactUs = lazy(() => import('./components/ContactUs'));

export default () => {
	const history = useHistory();
	history.listen((location, action) => {
		window.scrollTo(0, 0);
	});

	return (
		<>
			<Switch history={history}>
			<Route path="/checkout" exact render={(props) => <Checkout {...props} />} />


				<Route path="/login">
					<Layout>
						<Login />
					</Layout>
				</Route>

        <Route path="/refund-policy">
					<Layout>
						<RefundPolicy />
					</Layout>
				</Route>

				<Route path="/logout">
					<Layout>
						<Logout />
					</Layout>
				</Route>

				<Route path="/register">
					<Layout>
						<Register />
					</Layout>
				</Route>
        <Route path="/checkoutcs">
						<CheckoutCS />
				</Route>

        <Route path="/checkout" render={(props) => <Checkout {...props} />} />


				<Route path="/my-account">
					<Layout>
						<MyAccount />
					</Layout>
				</Route>


				<Route path="/cgv">
					<Layout>
						<CGV />
					</Layout>
				</Route>

				<Route path="/contact-us">
					<Layout>
						<ContactUs />
					</Layout>
				</Route>
			</Switch>
		</>
	);
};
