import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    base: {
        fontFamily: 'Noto Sans JP, sans-serif'
    },
    root: {
        marginRight: theme.spacing(1)
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    wrapper: {
        margin: '0',
        marginBottom: '90px',
    },
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        width: '100%',
    },
    buttonProgress: {
        marginLeft: '9px',
    },
    button: {
        margin: theme.spacing(1),
    },
    tabPanel: {
        margin: theme.spacing(1),
        minWidth: 200,
        width: `100%`
    },
    activeNavLink: {
        textDecoration: 'none',
        color: '#bdbdbd',
    },
    navLink: {
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    homeActiveNavLink: {
        textDecoration: 'none',
        color: 'black',
    },
    homeNavLink: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    title: {
        flexGrow: 1,
        fontWeight: 100,
        color: 'white',
        fontFamily: 'Noto Sans JP, sans-serif',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
        },
    },
    noColorTitle: {
        fontFamily: 'Noto Sans JP, sans-serif',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '30px',
        },
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    desktopNav: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'inherit',
        },
    },
    mobileNav: {
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
        },
        [theme.breakpoints.up('md')]: {
            display: 'inherit',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    footer: {
        position: 'relative',
        paddingTop: '18%'
    },
    loginButton: {
        width: '100%',
        padding: '15px 9px 15px 9px',
        backgroundColor: '#42bdff',
        color: 'white',
        border: 0,
        borderRadius: '6px',
        marginTop: '15px'
    },
    logOff: {
        position: 'absolute',
        zIndex: 9999,
        margin: 'auto',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: '#000',
        color: 'white',
        textAlign: 'center',
        paddingTop: '90px'
    },
    basicButton: {
        boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '9px',
        border: '0',
        backgroundColor: 'transparent',
        display: 'flex',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            padding: '12px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '15px',
        },
    },
    processDiv: {
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px 60px 30px',
            marginTop: '60px',
            borderRadius: '6px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '60px 30px 60px 30px',
            marginTop: '60px',
            borderRadius: '6px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '60px',
            marginTop: '60px',
            borderRadius: '6px'
        },
        backgroundColor: 'rgba(249, 249, 249, 0.37)',
        border: '1px solid rgba(0,0,0,0.12)',
    },
    fingerPrintIcon: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex', margin: '0 auto', fontSize: '35px'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex', margin: '0 auto', fontSize: '39px'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex', margin: '0 auto', fontSize: '45px'
        },
        color: 'gray'
    },
    homeGrid: {
        [theme.breakpoints.down('sm')]: {
            display: 'inherit'
        },
        [theme.breakpoints.up('md')]: {
            display: 'inherit'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid', gridTemplateColumns: '240px auto',
        },
    },
    homeMenu: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRight: '1px solid #e6e6e6',
            borderBottom: '1px solid #e6e6e6',
        },
    },
    accountIcon: {
        display: 'flex',
        margin: '0 auto',
        paddingTop: '30px',
        fontSize: '36px',
    },
    roundDiv: {
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: '30px 15px 30px 15px',
        border: '1px solid #e6e6e6',
    },
    roundDivGreen: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '9px',
            marginBottom: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        backgroundColor: '#42bdff',
        borderRadius: '6px',
        padding: '0px 15px 0px 15px',
        border: '1px solid #42bdff',
        color: 'white',
    },
    roundDivPadding: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '9px',
            marginBottom: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: '0px 15px 0px 15px',
        border: '1px solid #e6e6e6',
        color: 'black',
    },
    roundDivPurple: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '9px',
            marginBottom: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        backgroundColor: '#1a0095',
        borderRadius: '6px',
        padding: '0px 15px 0px 15px',
        border: '1px solid #1a0095',
        color: 'white',
    },
    homeCards: {
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            gridGap: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: 'auto auto auto',
            gridGap: '15px'
        },
    },
    homeLayoutGrid: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '12px', paddingRight: '12px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '15px', paddingRight: '15px'
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '30px', paddingRight: '30px'
        },
    },
    homeCardDiv: {
        marginTop: '15px', 
        marginBottom: '15px', 
        //border: '1px solid rgb(77, 20, 140)', 
        borderRadius: '6px', 
        height: '100%',
        textAlign: 'center',
    },
    saveButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '3px', marginBottom: '15px', backgroundColor: '#e3e3e3', marginRight: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px', marginBottom: '15px', backgroundColor: '#e3e3e3'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '15px', marginBottom: '15px', backgroundColor: '#e3e3e3'
        },
    },
    productGrid: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '15px',
            display: 'inherit',
            marginBottom: '90px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            display: 'grid',
            gridTemplateColumns: '25% 25% 25% 25%',
            gridGap: '3px',
            marginBottom: '90px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '15px',
            display: 'grid',
            gridTemplateColumns: '25% 25% 25% 25%',
            gridGap: '9px',
            marginBottom: '90px',
            paddingRight: '150px',
            paddingLeft: '150px'
        },
    },
    cardDiv: {
        margin: '3px',
        marginTop: '15px',
        padding: '30px',
        border: '1px solid #e8e8e8',
        borderRadius: '6px',
    },
    checkoutDiv: {
        margin: '15px',
        borderRadius: '6px',
    },
    purchasedProdDiv: {
        [theme.breakpoints.down('sm')]: {
            display: 'block', margin: '0 auto'
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid', gridTemplateColumns: 'auto auto auto', margin: '0 auto'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid', gridTemplateColumns: 'auto auto auto', margin: '0 auto'
        },
    },
    checkoutForm: {
        display: 'grid', justifyContent: 'center', gridTemplateColumns: 'auto auto'
    },
    homeMessage: {
        [theme.breakpoints.down('sm')]: {
            padding: '0px', marginTop: '10px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '0px', marginTop: '10px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px', marginTop: '10px'
        },
    },
    navLogo: {
        [theme.breakpoints.down('sm')]: {
            width: '90px', padding: '15px', cursor: 'pointer', float: 'right', paddingLeft: '0'
        },
        [theme.breakpoints.up('md')]: {
            width: '150px', padding: '15px', cursor: 'pointer'
        },
        [theme.breakpoints.up('lg')]: {
            width: '150px', padding: '15px', cursor: 'pointer'
        },  
    },
    checkoutInput: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '3px'
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '3px'
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '3px'
        },   
    },
    checkoutImage: {
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            width: '69%'
        },
        [theme.breakpoints.up('md')]: {
            width: '30%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '24%'
        },
    },
    productPageGrid: {
        [theme.breakpoints.down('sm')]: {
            display: 'block', paddingLeft: '6px', paddingRight: '6px', marginTop: '15px'
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid', gridTemplateColumns: '50% auto', gridGap: '30px', marginTop: '90px'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid', gridTemplateColumns: '50% auto', gridGap: '30px', marginTop: '90px'
        },
    },
    emailFormAlign: {
        [theme.breakpoints.up('md')]: {
            margin: '0 auto'
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0 auto'
        },
    },
    productSliderXS: {
        [theme.breakpoints.down('sm')]: {
            width: '30%',
            margin: '0 auto'
        },
        [theme.breakpoints.up('md')]: {
            width: '21%',
            margin: '0 auto'
        },
        [theme.breakpoints.up('lg')]: {
            width: '15%',
            margin: '0 auto'

        },
    },
    productSliderM: {
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            margin: '0 auto'
        },
        [theme.breakpoints.up('md')]: {
            width: '30%',
            margin: '0 auto'
        },
        [theme.breakpoints.up('lg')]: {
            width: '24%',
            margin: '0 auto'
        },
    },
    productSliderXL: {
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto' 
        },
        [theme.breakpoints.up('md')]: {
            width: '60%',
            margin: '0 auto'
        },
        [theme.breakpoints.up('lg')]: {
            width: '30%',
            margin: '0 auto'
        },
    },
    mobileSlider: {
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
    },
    desktopSlider: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
    },
    snCheckDiv: {
        display: 'grid', 
        gridTemplateColumns: 'auto 9%', 
        paddingLeft: '45px', 
        paddingRight: '30px'
    }
}));

export default useStyles;
