// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 475px) {\r\n\r\n  .footer_footerFontSize__3c1QN {\r\n    font-size: 11px\r\n  }\r\n\r\n}\r\n@media only screen and (min-width: 768px) {\r\n\r\n  .footer_textCenter__3TxgE {\r\n    text-align: center;\r\n  }\r\n  .footer_mxAuto__11Y4t {\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n  }\r\n\r\n}\r\n", "",{"version":3,"sources":["webpack://src/styles/footer.module.css"],"names":[],"mappings":"AAAA;;EAEE;IACE;EACF;;AAEF;AACA;;EAEE;IACE,kBAAkB;EACpB;EACA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;AAEF","sourcesContent":["@media screen and (max-width: 475px) {\r\n\r\n  .footerFontSize {\r\n    font-size: 11px\r\n  }\r\n\r\n}\r\n@media only screen and (min-width: 768px) {\r\n\r\n  .textCenter {\r\n    text-align: center;\r\n  }\r\n  .mxAuto {\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerFontSize": "footer_footerFontSize__3c1QN",
	"textCenter": "footer_textCenter__3TxgE",
	"mxAuto": "footer_mxAuto__11Y4t"
};
export default ___CSS_LOADER_EXPORT___;
