import { observable, action } from 'mobx';

export class UiStateStore {
    @observable 
    drawerState = false;
    
    @observable
    loadingState = false;

    @observable.ref
    loadingIcon = null;

    @action 
    setDrawerState(value: any) {
        this.drawerState = value;
    }

    @action 
    setLoadingState(value: any) {
        this.loadingState = value;
    }

    @action
    setLoadingIcon(value: any) {
        this.loadingIcon = value;
    }
}
