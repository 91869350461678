import { observable, action } from 'mobx';

export class FormStore {
    @observable
    formState: any = {
        username: '',
        password: '',
        email: ''
    }

    @action
    setFormState(name: any, value: any) {
        this.formState[name] = value;
    }

    @action
    clearFormState() {
        this.formState = {
            username: '',
            password: '',
            email: ''
        }
    }
}
