import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../styles/footer.module.css';

const Footer = function () {
	const connected = () => {
		if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
			return true;
		} else {
			return false;
		}
	};
	let history = useHistory();
	return (
		<Fragment>
			<footer className="footer footer-big text-white">
				<div className="container">
					<div className="content">
						<div className="footer-col row">
							<div className={styles.mxAuto + " footer-col col-md-6 col-12"}>
								<div className={styles.textCenter + " column text-uppercase"}>
									<h4 className="mb-4 text-white">Pages</h4>
									<ul className="links-vertical">
										<div className={styles.footerFontSize + " row"}>
											<div className="col">
												<li className="mb-3">
													<a
														onClick={() => {
															history.push('/');
														}}>
														Home
													</a>
												</li>
											</div>
                      <div className="col">
												<li className="mb-3">
													<a
														onClick={() => {
															history.push('/contact-us');
														}}>
														Contact us
													</a>
												</li>
                        </div>
											<div className="col">
												<li>
													<a
														onClick={() => {
															history.push('/cgv');
														}}>
														Privacy & Terms
													</a>
												</li>
											</div>
                      <div className="col">
												<li>
													<a
														onClick={() => {
															history.push('/refund-policy');
														}}>
														Refund Policy
													</a>
												</li>
											</div>
										</div>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<hr className="bg-white opacity-3" />
					<div className="column text-center">
						<span>Copyright &copy; 2022 Abo Server</span>
					</div>
				</div>
			</footer>
		</Fragment>
	);
};
const FooterTest = () => {
	return <div></div>;
};
export default Footer;
