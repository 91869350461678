import { observable, action } from 'mobx';

export class ProductPageStore {
    @observable
    product: any = {
        name: '',
        description: '',
        price: ''
    };

    @observable
    premiumIPTV: object = {};

    @action
    setProduct(value: object) {
        this.product = value;
    }

    setPremiumIPTV(value: object) {
        this.premiumIPTV = value;
    }
}
