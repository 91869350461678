import Checkout from './Checkout';
import Layout from './Layout';

const CheckoutCS = () => (
  <Layout>
    <section
		className="section section-shaped section-lg bg-gradient-default"
		style={{ minHeight: '100vh' }}>
		<div
			className="container bg-white mt-8 pt-5 rounded shadow-lg"
			style={{ background: 'none' }}>
			<Checkout />
		</div>
	</section>
  </Layout>
);

export default CheckoutCS;
