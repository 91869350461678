import { observable, action } from 'mobx';

export class CartStore {
    @observable
    products: any = [];

    @observable
    sn1: string = '';

    @observable
    sn2: string = '';

    @observable
    sn3: string = '';

    @action
    setProducts(value: any) {
        this.products = value;
    }

    @action
    addProduct(value: any) {
        this.products.push(value);
    }

    @action
    setSN1(value: string) {
        this.sn1 = value;
    }

    @action
    setSN2(value: string) {
        this.sn2 = value;
    }

    @action
    setSN3(value: string) {
        this.sn3 = value;
    }
}
